.tier-section{
    height: 200px;
    width: 200px;
    border-radius: 50%;
    box-shadow: 0px 14px 55px rgba(0, 0, 0, 0.11);
    padding: 1.5rem;
}

.reward-icon{
    height: 35px;
    width: 35px;
    border-radius: 50%;
}

.tier-percentage{
    bottom: 0;
}
.tier-info{
    line-height: 1;
}

.progress-bar .progress-bar-label{
position: absolute;
width: 100%;
}